import React, { useEffect, useState, FC } from 'react';
import { Body1, ehiTheme } from '@ehi/ui';
import { Box, Grid, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslations } from 'components/shared/i18n';
import {
  AdditionalInfoCaption,
  AdditionalInfoEditButton,
  AdditionalInfoSubtitle2,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { DOUBLE_DASH } from 'utils/constants';
import { AdditionalInformationWithReservation } from 'components/flexFlow/rateAndBilling/RateAndBillingTypes';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';

type AdditionalInfoProps = {
  additionalInfo: AdditionalInformationWithReservation[];
};

export const AdditionalInformationSection: FC<AdditionalInfoProps> = ({ additionalInfo }) => {
  const { t } = useTranslations();
  const [isExpanded, setIsExpanded] = useState(additionalInfo.length > 3);
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const showCaret = additionalInfo.length > 3;

  useEffect(() => {
    setIsExpanded(additionalInfo.length > 3);
  }, [additionalInfo]);

  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };
  const toggleExpand = () => {
    if (!showCaret) return;
    setIsExpanded((prev) => !prev);
  };

  return (
    <Box display='flex' flexDirection='column' onClick={toggleExpand} data-testid='additional-information-container'>
      <Grid container display={'flex'} alignItems={'center'}>
        <Grid item xs={9} sm={9}>
          <Box display='flex' alignItems='center' data-testid='additional-info-header'>
            <AdditionalInfoSubtitle2 bold data-testid='additional-info-title'>
              {t('rateAndBilling.additionalInformation')}
            </AdditionalInfoSubtitle2>
            {!isReadOnly && (
              <AdditionalInfoEditButton size='small' variant='text' onClick={handleOnClick} data-testid='edit-button'>
                {t('common.edit')}
              </AdditionalInfoEditButton>
            )}
          </Box>
        </Grid>
        {showCaret && (
          <Grid item xs={3} sm={3} display={'flex'} justifyContent={'flex-end'}>
            <IconButton data-testid='toggle-expand-button'>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Box style={{ margin: ehiTheme.spacing(1.2, 0) }}>
        <Grid container spacing={2} data-testid='additional-info-fields'>
          {additionalInfo.slice(0, showCaret && !isExpanded ? 3 : additionalInfo.length).map((field) => (
            <Grid item xs={12} sm={4} key={field.urn} data-testid={`info-field-${field.urn}`}>
              <Box>
                <AdditionalInfoCaption data-testid={`info-caption-${field.urn}`}>
                  {field?.translation?.name}
                  {field.isRequiredForReservation && <span>*</span>}
                </AdditionalInfoCaption>
                <Body1 data-testid={`info-helper-text-${field.urn}`}>{DOUBLE_DASH}</Body1>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
