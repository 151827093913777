import { parseUrn } from 'utils/urnUtils';
import {
  AdditionalInformationBase,
  RentalStage,
  ReservationAccount,
  ReservationConfiguration,
} from 'services/businessAccount/businessAccountTypes';

export type RateAndBillingInfo = {
  urn?: string;
  number?: string;
  name?: string;
  type?: string;
  reservationConfiguration?: ReservationConfiguration;
};
import { RATE_SOURCE } from 'components/flexFlow/driver/driverSearch/driverSearchUtils';
import { AdditionalInformationWithReservation } from 'components/flexFlow/rateAndBilling/RateAndBillingTypes';

export const transformRateSourceInfoFromApi = (accountDetails: ReservationAccount): RateAndBillingInfo => {
  return {
    urn: accountDetails.urn,
    number: parseUrn(accountDetails.urn),
    name: accountDetails.name,
    type: parseUrn(accountDetails.type),
    reservationConfiguration: accountDetails.reservationConfiguration,
  };
};

export const transformAdditionalInformationInfoFromApi = (
  additionalInfo: AdditionalInformationBase,
  accountType: string
): AdditionalInformationWithReservation[] => {
  if (!Array.isArray(additionalInfo)) return [];

  const filteredFields =
    accountType === RATE_SOURCE
      ? additionalInfo.filter((field) => !field.onlyForBusinessPayer)
      : additionalInfo.filter((field) => field.onlyForBusinessPayer);

  return filteredFields.map((field) => ({
    ...field,
    isRequiredForReservation: field.requiredRentalStages.includes(RentalStage.RESERVATION),
  }));
};
