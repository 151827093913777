import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from 'components/shared/i18n';
import { BranchLookupFields, SearchByType } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { StyledFormLabel } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { RadioGroup } from '@mui/material';

export const BranchLookupRadioGroup: FC = () => {
  const { t } = useTranslations();
  const { field } = useController({ name: BranchLookupFields.SearchBy, defaultValue: SearchByType.Keyword });
  const { setValue } = useFormContext();

  const keywordOnClick = () => {
    setValue(BranchLookupFields.HideDistance, true);
    setValue(BranchLookupFields.SearchBy, SearchByType.Keyword);
  };

  const postalCodeOnClick = () => {
    setValue(BranchLookupFields.HideDistance, false);
    setValue(BranchLookupFields.SearchBy, SearchByType.PostalCode);
  };

  return (
    <RadioGroup row {...field}>
      <StyledFormLabel id='branchLookupRadioGroupLabel' data-testid={'searchByLabel'}>
        {t('location.searchBy')}
      </StyledFormLabel>
      <FormControlLabel
        value={SearchByType.Keyword}
        control={<Radio color='primary' data-testid={'keywordRadio'} />}
        onClick={keywordOnClick}
        label={t('whenWhere.searchByKeyWord')}
        labelPlacement='end'
      />
      <FormControlLabel
        value={SearchByType.PostalCode}
        control={<Radio color='primary' data-testid={'postalCodeRadio'} />}
        onClick={postalCodeOnClick}
        label={t('location.postalCode')}
        labelPlacement='end'
      />
    </RadioGroup>
  );
};
