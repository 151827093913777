import { fetchDomainQuery, useDomainQuery } from 'context/queryClient/queryUtil';
import { useQueries, UseQueryResult } from '@tanstack/react-query';
import {
  BranchEffectivePolicy,
  BranchHierarchy,
  BranchV2,
  Countries,
  CountrySubdivision,
  GroupSummary,
  MethodOfPayment,
} from 'services/location/locationTypes';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { Location } from 'services/location/locationRollupTypes';
import { useSetLocationService } from 'services/location/useSetLocationService';
import { QUERY_KEY_LOCATION } from 'services/location/locationCache';
import {
  getBranchByPeopleSoftId,
  getBranchHierarchy,
  getCountries,
  getCountrySubdivisions,
  getGroups,
  getPaymentMethodsByLocation,
  getRentalBrands,
  retrieveBranchEffectivePolicies,
} from 'services/location/locationService';
import { parseUrn } from 'utils/urnUtils';
import { OptionItem } from 'components/shared/forms/FormFieldTypes';
import { Brand, COUNTRY_CODE_US, EMPTY_VALUE } from 'utils/constants';
import { DOMAIN_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import { DAILY_RENTAL, getBrandOptions, getGroupOptions } from 'utils/branchLookupUtils';
import { loadEhiLocationCookie } from '@ehi/location';
import { useMemo } from 'react';
import {
  generateOptionItemsWithCorporateCountries,
  generateOptionItemsWithNonCorporateCountries,
} from 'utils/formUtils';
import { RentalBrand } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { getBranchCountry, getBranchGroup } from 'utils/locationUtils';
import { Pickup } from 'services/booking/bookingTypes';
import { TFunction } from 'i18next';

const COUNTRIES_QUERY_KEY = 'countries';
const SUB_DIVISION_QUERY_KEY = 'subDivision';
const BRANCH_INFO_QUERY_KEY = 'branch';
const BRANCH_HIERARCHY_QUERY_KEY = 'branchHierarchy';
const BRANCH_INFO_BY_URN_QUERY_KEY = 'branchInfoByUrn';
const QUERY_KEY_LOCATION_GROUP = 'groups';
const RENTAL_BRANDS = 'rentalBrands';
const BRANCH_EFFECTIVE_POLICIES = 'branchEffectivePolicies';
const LOCATION_PAYMENT_METHODS = 'locationPaymentMethods';

export const useCountriesQuery = (): UseQueryResult<Countries, EhiErrors> => {
  return useDomainQuery({ queryKey: COUNTRIES_QUERY_KEY, queryFn: getCountries });
};

export const useSubdivisionsQuery = (country: string): UseQueryResult<CountrySubdivision[], EhiErrors> => {
  return useDomainQuery({
    queryKey: [SUB_DIVISION_QUERY_KEY, country],
    queryFn: () => getCountrySubdivisions(country),
    enabled: !!country,
  });
};

export const useBranchInfoByPeopleSoftIdQuery = (locationId: string): UseQueryResult<BranchV2, EhiErrors> => {
  return useDomainQuery({
    queryKey: [BRANCH_INFO_QUERY_KEY, locationId],
    queryFn: () => getBranchByPeopleSoftId(locationId),
    enabled: !!locationId,
  });
};

export const useLocationQuery = (locationId: string): UseQueryResult<Location> => {
  const { getLocation } = useSetLocationService();
  return useDomainQuery({
    queryKey: [QUERY_KEY_LOCATION, locationId],
    queryFn: () => getLocation(locationId),
    enabled: !!locationId,
  });
};

export const useBranchInfoByUrnQuery = (
  urn: string | undefined,
  isEnabled = true
): UseQueryResult<BranchV2, EhiErrors> => {
  return useDomainQuery({
    queryKey: [BRANCH_INFO_BY_URN_QUERY_KEY, urn],
    queryFn: () => getBranchByPeopleSoftId(parseUrn(urn)),
    enabled: !!urn && isEnabled,
  });
};

export const fetchBranch = (locationId: string) => {
  return fetchDomainQuery({
    queryKey: [BRANCH_INFO_QUERY_KEY, locationId],
    queryFn: () => getBranchByPeopleSoftId(locationId),
  });
};

export const useLocationGroups = (
  countryCode: string,
  capabilities: string,
  isEnabled = true
): UseQueryResult<GroupSummary[], EhiErrors> => {
  return useDomainQuery({
    queryKey: [QUERY_KEY_LOCATION_GROUP, countryCode],
    queryFn: () => getGroups(countryCode, capabilities),
    enabled: !!countryCode && isEnabled,
  });
};

export const useRentalBrandsQuery = (): UseQueryResult<RentalBrand[]> => {
  return useDomainQuery({
    queryKey: RENTAL_BRANDS,
    queryFn: getRentalBrands,
  });
};

export const useRetrieveBranchEffectivePolicies = (
  branchId: string,
  t: TFunction<'translation'>
): UseQueryResult<BranchEffectivePolicy[], EhiErrors> => {
  return useDomainQuery({
    queryKey: [BRANCH_EFFECTIVE_POLICIES, branchId],
    queryFn: () => retrieveBranchEffectivePolicies(branchId, t),
    enabled: !!branchId,
  });
};

export const useGetPaymentMethodsByLocation = (locationId: string): UseQueryResult<MethodOfPayment[]> => {
  return useDomainQuery({
    queryKey: [LOCATION_PAYMENT_METHODS, locationId],
    queryFn: () => getPaymentMethodsByLocation(locationId),
    enabled: !!locationId,
  });
};

export const useGetBranchHierarchy = (locationId: string): UseQueryResult<BranchHierarchy, EhiErrors> => {
  return useDomainQuery({
    queryKey: [BRANCH_HIERARCHY_QUERY_KEY, locationId],
    queryFn: () => getBranchHierarchy(locationId),
    enabled: !!locationId,
  });
};

// Indices for location queries. These are set by the order they are sent in for useQueries
const COUNTRIES_INDEX = 0;
const GROUPS_INDEX = 1;
const BRANDS_INDEX = 2;

export type LocationInfoQueries = {
  corporateCountries: OptionItem[];
  otherCountries: OptionItem[];
  branchCountryCode: string;
  branchGroup: string;
  groups: OptionItem[];
  brands: string[];
  locationQueriesComplete: boolean;
};

/**
 * Use this to run all the location queries before displaying the info to the user
 * @param pickupData If passed in, uses the start location over user's set location
 * @returns All results of location queries and flag to indicate all queries are complete
 */
export const useLocationInfoQueries = (pickupData?: Pickup): LocationInfoQueries => {
  const cookie = loadEhiLocationCookie();

  const { data: branchLocation, isLoading: isBranchHierarchyFetching } = useGetBranchHierarchy(
    pickupData ? parseUrn(pickupData?.branch) : cookie?.peoplesoftId ?? EMPTY_VALUE
  );
  const results: UseQueryResult[] = useQueries({
    queries: [
      { queryKey: [COUNTRIES_QUERY_KEY], queryFn: getCountries, ...DOMAIN_CACHE_OPTIONS },
      {
        queryKey: [QUERY_KEY_LOCATION_GROUP, branchLocation?.branch.countryCode ?? COUNTRY_CODE_US],
        queryFn: () => getGroups(branchLocation?.branch.countryCode ?? COUNTRY_CODE_US, DAILY_RENTAL),
        ...DOMAIN_CACHE_OPTIONS,
        enabled: !!branchLocation,
      },
      {
        queryKey: [RENTAL_BRANDS],
        queryFn: getRentalBrands,
        ...DOMAIN_CACHE_OPTIONS,
      },
    ],
  });

  const countries: Countries = useMemo(() => {
    if (results[COUNTRIES_INDEX]) {
      return (results[COUNTRIES_INDEX].data as Countries) ?? [];
    }
    return [];
  }, [results]);
  const corporateCountries = useMemo(() => generateOptionItemsWithCorporateCountries(countries), [countries]);
  const otherCountries = useMemo(() => generateOptionItemsWithNonCorporateCountries(countries), [countries]);

  const groups: OptionItem[] = useMemo(() => {
    if (results[GROUPS_INDEX]) {
      return getGroupOptions(results[GROUPS_INDEX].data as GroupSummary[]);
    }
    return [];
  }, [results]);

  const brands: string[] = useMemo(() => {
    if (results[BRANDS_INDEX]) {
      return getBrandOptions(results[BRANDS_INDEX].data as RentalBrand[]);
    }
    return [Brand.ENTERPRISE, Brand.ALAMO, Brand.NATIONAL];
  }, [results]);

  const locationQueriesComplete = useMemo(() => {
    return !isBranchHierarchyFetching && results.filter((result) => result.isFetching).length === 0;
  }, [isBranchHierarchyFetching, results]);

  return {
    corporateCountries,
    otherCountries,
    branchCountryCode: isBranchHierarchyFetching ? EMPTY_VALUE : getBranchCountry(branchLocation),
    branchGroup: isBranchHierarchyFetching ? EMPTY_VALUE : getBranchGroup(branchLocation),
    groups,
    brands,
    locationQueriesComplete,
  };
};
