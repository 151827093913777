import { FC, useMemo } from 'react';
import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import { Body1, Body2, ehiTheme } from '@ehi/ui';
import { Caption2, OverlineText } from 'components/shared/ui/styles/Typography.styles';
import { useTranslations } from 'components/shared/i18n';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { RouterPaths } from 'app/router/RouterPaths';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { LoyaltyProgramLogo } from 'components/shared/ui/logos/LoyaltyProgramLogo';
import { formatName } from 'components/shared/uiModels/driver/driverTransformer';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { StyledWarningIcon } from 'components/shared/ui/styles/WarningIcon.styles';
import { useAppSelector } from 'redux/hooks';
import { selectAdditionalDrivers } from 'redux/selectors/bookingEditor';
import { AdditionalDriver } from 'services/booking/bookingTypes';
import { DividerWithMargin } from 'components/shared/ui/styles/Divider.styles';

export const DriversSummary: FC = () => {
  const { t } = useTranslations();
  const additionalDriverProfiles = useAppSelector(selectAdditionalDrivers);
  const { driverData, isRenterProfileLoading, isRenterProfileError } = useGetRenterProfile();
  const { data: phoneTypeDomain, isFetching: isPhoneTypeLoading, isError: isPhoneTypeError } = usePhoneTypesQuery();
  const phoneType = useMemo(() => {
    return phoneTypeDomain?.find((phone) => phone.code === driverData?.primaryPhone?.type)?.name;
  }, [driverData?.primaryPhone?.type, phoneTypeDomain]);

  const noProfileAdditionalDrivers = useMemo(() => {
    if (additionalDriverProfiles && additionalDriverProfiles?.length > 0) {
      return additionalDriverProfiles?.filter((driver) => !driver.profile);
    }
    return [];
  }, [additionalDriverProfiles]);

  const profileAdditionalDrivers = useMemo(() => {
    if (additionalDriverProfiles && additionalDriverProfiles?.length > 0) {
      return additionalDriverProfiles?.filter((driver) => !!driver.profile);
    }
    return [];
  }, [additionalDriverProfiles]);

  const additionalDriverNames = useMemo(() => {
    return (
      profileAdditionalDrivers?.map((driver: AdditionalDriver) =>
        formatName(driver.name?.surname, driver.name?.given)
      ) ?? []
    );
  }, [profileAdditionalDrivers]);

  const renderAdditionalDrivers = () => {
    const driversToShow = additionalDriverNames.slice(0, 2);
    const hasMoreDrivers =
      additionalDriverNames.length > 2 || (additionalDriverNames.length === 2 && noProfileAdditionalDrivers.length > 0);
    const showOnlyNoProfileDrivers = additionalDriverNames.length < 2 && noProfileAdditionalDrivers.length > 0;
    const moreDriversCount =
      additionalDriverProfiles?.length && additionalDriverProfiles?.length > 2
        ? additionalDriverProfiles?.length - 2
        : undefined;

    return (
      <div data-testid='additionalDriversSummary'>
        <DividerWithMargin />
        <Caption2>{t('driver.additionalDrivers.additionalDrivers')}</Caption2>
        {driversToShow.map((driver, index) => (
          <Body1 key={index} data-testid={`additionalDriver-${index}`}>
            {driver}
          </Body1>
        ))}
        {showOnlyNoProfileDrivers && (
          <Body1 data-testid={`additionalDriver-no-profile`}>
            {noProfileAdditionalDrivers.length + ' '}
            {noProfileAdditionalDrivers.length > 1
              ? t('driver.additionalDrivers.additionalDriversSummary')
              : t('driver.additionalDrivers.additionalDriver')}
          </Body1>
        )}
        {hasMoreDrivers && (
          <OverlineText data-testid='moreAdditionalDrivers'>
            {'+' + moreDriversCount + ' '}
            {t('driver.additionalDrivers.more')}
          </OverlineText>
        )}
      </div>
    );
  };

  return (
    <ReservationSummaryCard
      title={t('reservationSummary.drivers')}
      loading={mapLoadingState(isRenterProfileLoading || isPhoneTypeLoading, isRenterProfileError || isPhoneTypeError)}
      path={RouterPaths.Driver}
      data-testid='driversSummaryCard'>
      <CardContent data-testid='driversSummaryContent'>
        <Grid container direction={'column'}>
          <Grid item xs data-testid='primaryDriver'>
            <Caption2>{t('driver.primary')}</Caption2>
            <Body1 bold data-testid='primaryDriverName'>
              {/* Note: get driver profile doesn't return returnWarningFlag, so look for renterWarning object */}
              {driverData?.renterWarning && <StyledWarningIcon data-testid='summaryRenterWarning' />}
              {formatName(driverData?.lastName, driverData?.firstName) ?? '--'}
            </Body1>
          </Grid>
          <Grid item xs data-testid='contactPhone'>
            <Body2 data-testid='contactPhoneValue'>
              {phoneType} {formatPhoneNumber(driverData?.primaryPhone?.number)}
            </Body2>
          </Grid>
          <Grid item xs data-testid='email'>
            <Body2 data-testid='emailValue'>{driverData?.email}</Body2>
          </Grid>
          <Grid item xs data-testid='loyalty'>
            {driverData?.loyaltyType && (
              <div style={{ margin: ehiTheme.spacing(2, 0) }}>
                <Caption2>{t('driver.loyalty')}</Caption2>
                <Body2 data-testid='loyaltyType'>{driverData.loyaltyProgram?.name}</Body2>
                <Body2>
                  <LoyaltyProgramLogo membershipProgram={driverData.loyaltyType} height={24} width={64} />
                </Body2>
                <Body2 data-testid='loyaltyNumber'>{driverData.loyaltyNumber}</Body2>
              </div>
            )}
          </Grid>
          <Grid item xs>
            {(additionalDriverNames.length > 0 || noProfileAdditionalDrivers.length > 0) && renderAdditionalDrivers()}
          </Grid>
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
