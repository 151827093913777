import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { TRANSACTION_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import {
  getAccountContactInfo,
  retrieveAdditionalInformation,
  retrieveBillingAccount,
  retrieveBusinessAccount,
} from 'services/businessAccount/businessAccountService';
import { BillingAccount, ContactInformation, ReservationAccount } from 'services/businessAccount/businessAccountTypes';
import { useResScopeQuery } from 'context/queryClient/queryUtil';
import { RATE_SOURCE } from 'components/flexFlow/driver/driverSearch/driverSearchUtils';
import { BILL_TO } from 'utils/constants';
import { AdditionalInformationWithReservation } from 'components/flexFlow/rateAndBilling/RateAndBillingTypes';

const BUSINESS_ACCOUNT_QUERY_KEY = 'businessAccount';
const BILLING_ACCOUNT_NUMBER_QUERY_KEY = 'billingAccountNumber';
const ACCOUNT_CONTACT_QUERY_KEY = 'accountContact';
const ADDITIONAL_INFORMATION_QUERY_KEY = 'additionalInformation';
// ToDo use useResScopeQuery
export const useBusinessAccountQuery = (
  accountNumber: string,
  queryOptions: Partial<UseQueryOptions<ReservationAccount, EhiErrors>> = {}
): UseQueryResult<ReservationAccount, EhiErrors> => {
  const queryKey = [BUSINESS_ACCOUNT_QUERY_KEY, accountNumber];
  const queryFn = () =>
    accountNumber ? retrieveBusinessAccount(accountNumber) : Promise.reject(new Error('Account number is undefined'));
  const options = {
    ...TRANSACTION_CACHE_OPTIONS,
    enabled: !!accountNumber,
    ...queryOptions,
  };

  return useQuery<ReservationAccount, EhiErrors>(queryKey, queryFn, options);
};

export const useBillingAccountByAccountQuery = (accountNumber: string): UseQueryResult<BillingAccount, EhiErrors> => {
  return useResScopeQuery({
    queryKey: [BILLING_ACCOUNT_NUMBER_QUERY_KEY, accountNumber],
    queryFn: () => retrieveBillingAccount({ accountNumber: accountNumber.trim().toUpperCase() }),
    enabled: !!accountNumber,
  });
};

export const useAccountContactInfoQuery = (accountNumber: string): UseQueryResult<ContactInformation, EhiErrors> => {
  return useResScopeQuery({
    queryKey: [ACCOUNT_CONTACT_QUERY_KEY, accountNumber],
    queryFn: () => getAccountContactInfo(accountNumber),
    enabled: !!accountNumber,
  });
};

export const useAdditionalInformationQuery = (
  accountNumber = '',
  accountType: typeof RATE_SOURCE | typeof BILL_TO
): UseQueryResult<AdditionalInformationWithReservation[], EhiErrors> => {
  return useResScopeQuery({
    queryKey: [ADDITIONAL_INFORMATION_QUERY_KEY, accountNumber, accountType],
    queryFn: () => {
      return retrieveAdditionalInformation(accountNumber.trim().toUpperCase(), accountType);
    },
    enabled: !!accountNumber && accountNumber.length > 0,
  });
};
