import { FC, useCallback } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { piiField } from '@ehi/support';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import {
  DVLA_LICENSE_VALUE,
  FRANCE_LICENSE_FIELDS,
  GERMANY_LICENSE_FIELDS,
  getAgeOption,
  resetFieldsClearErrors,
  SPAIN_LICENSE_FIELDS,
} from 'components/flexFlow/driver/driverForm/driverFormUtils';
import { FormSectionHeader, Section } from 'components/flexFlow/driver/driverForm/DriverForm.styles';
import { CorporateCountry } from 'utils/countryUtils';
import {
  IdentificationNumberField,
  LicenseDVLAField,
  LicenseIssuerField,
  PlaceOfBirthField,
  SpainSubSection,
} from 'components/flexFlow/driver/driverForm/formSection/LicenseSubSections';
import { DateTime } from 'luxon';
import { calculateAge } from 'utils/dateUtils';
import { FormTextField } from 'components/shared/forms/FormTextField';

import { EMPTY_VALUE } from 'utils/constants';
import { useAppSelector } from 'redux/hooks';
import { selectPickup, selectReturn } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { DriverFormFields, DriverType } from 'components/flexFlow/driver/driverForm/DriverFormTypes';

export const LicenseSection: FC = () => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const pickupInfo = useAppSelector(selectPickup);
  const returnInfo = useAppSelector(selectReturn);
  const { clearErrors, resetField, setValue } = useFormContext();
  const [licenseCountry, driverType] = useWatch({
    name: [DriverFormFields.LicenseCountry, DriverFormFields.DriverType],
  });

  const validateExpirationDate = useCallback(
    async (date: DateTime | string) => {
      let selectedDate: string | null = null;
      if (date as DateTime) {
        selectedDate = (date as DateTime).toISO();
      } else if (date as string) {
        selectedDate = date as string;
      }
      if (!(pickupInfo?.dateTime || returnInfo?.dateTime) || !selectedDate) {
        return;
      }

      const message = t('driver.warnings.expDateInPast');
      if (returnInfo?.dateTime) {
        const returnDateTime = new Date(returnInfo.dateTime);
        if (Date.parse(selectedDate) < Date.parse(returnDateTime.toISOString())) {
          await showAlert({
            description: message,
          });
        }
      } else if (pickupInfo?.dateTime) {
        const pickupDateTime = new Date(pickupInfo.dateTime);
        if (Date.parse(selectedDate) < Date.parse(pickupDateTime.toISOString())) {
          await showAlert({
            description: message,
          });
        }
      }
    },
    [pickupInfo?.dateTime, returnInfo?.dateTime, showAlert, t]
  );

  const setAgeDropdown = async (date: DateTime | string) => {
    if (!date) {
      setValue(DriverFormFields.Age, EMPTY_VALUE);
    } else {
      const age = calculateAge(date);
      const ageOption = age && getAgeOption(age);
      setValue(DriverFormFields.Age, ageOption);
    }
  };

  const handleLicenseCountryChange = useCallback(
    (newLicenseCountry: string) => {
      resetFieldsClearErrors([DriverFormFields.LicenseIssuerRegion], resetField, clearErrors);
      licenseCountry === CorporateCountry.Germany &&
        resetFieldsClearErrors(GERMANY_LICENSE_FIELDS, resetField, clearErrors);
      licenseCountry === CorporateCountry.France &&
        resetFieldsClearErrors(FRANCE_LICENSE_FIELDS, resetField, clearErrors);
      licenseCountry === CorporateCountry.Spain &&
        resetFieldsClearErrors(SPAIN_LICENSE_FIELDS, resetField, clearErrors);

      setValue(DriverFormFields.LicenseCountry, newLicenseCountry);
      if (newLicenseCountry === CorporateCountry.Spain) {
        setValue(DriverFormFields.LicenseIssuerAuth, CorporateCountry.Spain);
      } else if (newLicenseCountry === CorporateCountry.GreatBritain) {
        setValue(DriverFormFields.LicenseIssuerAuth, DVLA_LICENSE_VALUE);
      } else {
        setValue(DriverFormFields.LicenseIssuerAuth, EMPTY_VALUE);
        setValue(DriverFormFields.LicenseIssuerRegion, EMPTY_VALUE);
      }
    },
    [clearErrors, licenseCountry, resetField, setValue]
  );

  return (
    <Section className={piiField} data-testid={'licenseSection'}>
      <FormSectionHeader>{t('driver.license')}</FormSectionHeader>
      <GridContainer>
        <GridItem sm={6}>
          <CountrySelectField
            name={DriverFormFields.LicenseCountry}
            label={t('driver.issuingCountry')}
            data-testid={'licenseCountry'}
            disabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE || driverType === DriverType.DRIVER_PROFILE_DNR}
            onChange={(event) => handleLicenseCountryChange(event.target.value)}
          />
        </GridItem>
      </GridContainer>
      {licenseCountry && (
        <>
          <GridContainer>
            <GridItem sm={6} data-testid={'licenseNumberGridItem'}>
              <FormTextField
                name={DriverFormFields.LicenseNumber}
                label={t('driver.licenseHash')}
                data-testid={'licenseNumber'}
                type='text'
                inputProps={{ maxLength: 50 }}
                disabled={
                  driverType === DriverType.LOYALTY_DRIVER_PROFILE || driverType === DriverType.DRIVER_PROFILE_DNR
                }
                required
              />
            </GridItem>
            <LicenseIssuerField
              licenseCountry={licenseCountry}
              isDisabled={
                driverType === DriverType.LOYALTY_DRIVER_PROFILE || driverType === DriverType.DRIVER_PROFILE_DNR
              }
            />
          </GridContainer>
          <GridContainer>
            {licenseCountry === CorporateCountry.Germany && <IdentificationNumberField />}
            <GridItem sm={4} data-testid={'licenseExpirationGridItem'}>
              <DatePickerField
                name={DriverFormFields.LicenseExpirationDate}
                label={t('driver.expDate')}
                required={
                  licenseCountry === CorporateCountry.UnitedStates || licenseCountry === CorporateCountry.Canada
                }
                disabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE}
                submitOnChange={validateExpirationDate}
              />
            </GridItem>
            {licenseCountry === CorporateCountry.GreatBritain && <LicenseDVLAField />}
            <GridItem sm={4} data-testid={'licenseIssuedDateGridItem'}>
              <DatePickerField
                name={DriverFormFields.LicenseIssuedDate}
                label={t('driver.issueDate')}
                disableFuture
                disabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE}
              />
            </GridItem>
            <GridItem sm={4} data-testid={'dobGridItem'}>
              <DatePickerField
                name={DriverFormFields.DOB}
                label={t('driver.dateOfBirth')}
                disableFuture
                disabled={driverType !== undefined}
                required
                submitOnChange={setAgeDropdown}
              />
            </GridItem>
            {licenseCountry === CorporateCountry.France && (
              <PlaceOfBirthField isDisabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE} />
            )}
          </GridContainer>
          {licenseCountry === CorporateCountry.Spain && (
            <SpainSubSection isDisabled={driverType === DriverType.LOYALTY_DRIVER_PROFILE} />
          )}
        </>
      )}
    </Section>
  );
};
